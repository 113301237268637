export const resources = {
  en: {
    TEST: "Details",
    Information: "Information",
    Global: {
      Today: "Today",
      h: "h",
      Loading: "Loading",
      Details: "Details",
      Information: "Statistics",
      Modules: "Modules",
      Groups: "Groups",
    },
    DatePicker: {
      Today: "Today",
      Clear: "Clear",
    },
    DateRangePicker: {
      StartDate: "Start date",
      EndDate: "End date",
    },
    Button: { Send: "Send", Download: "Download" },
    Distributor: {
      Report: "Reports",
      DateFrom: "From date",
      DateTo: "To date",
      Status: "Status",
      StatusReady: "Ready",
      StatusPlanned: "Pending",
      Actions: "Actions",
    },
    Modules: "Modules",
    AllModules: "All Modules",
    MyModules: {
      title: "My modules",
      levelPlaceholder: "Select level",
      typePlaceholder: "Select type",
    },
    Login: {
      title: "Sign in",
      subTitle: "Fill in the fields below to sign into your account.",
      email: "Email address",
      password: "Password",
      restorePasswordTitle: "Password Recovery",
      restorePasswordSubTitle:
        'Enter the email address you provided during registration and click the "Restore" button',
      restorePassword: "Forgot your passsword?",
      toSignIn: "Back to sign in page",
      restore: "Restore",
      restorePasswordSuccess:
        "Within a few minutes you will receive an email with instructions on how to restore your password.",
      toRegister: "Register",
    },
    Register: {
      title: "Sign Up",
      subTitle: "Fill in the fields below to sign up.",
      email: "Email address",
      firstName: "First Name",
      lastName: "Last Name",
      phone: "Phone",
      password: "Password",
      passwordConfirmation: "Password Confirmation",
      toSignIn: "Back to sign in page",
      requiredFieldError: "This field is required",
      invalidEmailError: "Invalid email",
      invalidPhoneError: "Invalid phone number",
      tooShortError: "Too short",
      toLogin: "Login",
    },
    Menu: {
      Dashboard: "Dashboard",
      Groups: "Groups",
      Reports: "Reports",
      Classes: "Classes",
      Modules: "Modules",
      "My modules": "My modules",
      "My words": "My words",
      "My courses": "My courses",
      "Teacher’s corner": "Teacher’s corner",
      "Student’s corner": "Student’s corner",
      "Additional modules": "Additional modules",
      "Useful links": "Useful links",
      Chat: "Chat",
      Student: "Student",
      Teacher: "Teacher",
      Coordinator: "Coordinator",
      PayrollAccountant: "Payroll Accountant",
      Attendance: "Attendance",
      Teachers: "Teachers",
      Salaries: "Salaries",
      Odds: "Odds",
      Summary: "Summary",
      Companies: "Companies",
      Cancellation: "Cancellations",
      AllPayrollGroups: "Surcharges",
    },
    LessonStatuses: {
      planned: "Planned",
      completed: "Completed",
      earlyCanceledByCustomer: "Student early cancellation",
      earlyCanceledBySchool: "DS early cancellation",
      lateCanceledByCustomer: "Student late cancellation",
      lateCanceledBySchool: "DS late cancellation",
      notPerformedToPay: "Not Performed To Pay",
    },
    LessonCredentials: {
      copied: "Copied to clickboard",
    },
    Footer: {
      "Sign up club": "Sign up for the Fun Friday conversation club",
      "Sign up classes": "Sign up for practical classes",
      "Practice on AI": "Practice on AI language simulators",
      About: "About company Progress",
      Facebook: "Facebook",
      Twitter: "Twitter",
      UserAgreement: "User agreement",
      VKontakte: "vKontakte",
      "Privacy Policy": "Privacy Policy",
      Copyright: "Copyright © 2020. Jet Class. All rights reserved.",
    },
    Progress: {
      totalProgress: "Total Progress",
      completed: "completed",
      yourProgress: "Your Progress:",
    },
    BottomPanel: {
      Select: "Select",
      Reload: "Reload",
      Next: "Next",
    },
    CardClass: {
      WatchRecording: "Watch the recording",
      Cancelation: "Student late cancellation",
      Join: "Join the webinar",
    },
    CardModule: {
      ends: "Ends on {{date, string}}",
      continue: "Continue",
      progressTitleTotal: "Total Progress",
      progressTitleResult: "Result",
      progressTitleUnits: "Hours",
      progressTitleClasses: "Classes",
    },
    StartPageStudent: {
      myClasses: "My classes",
      myModules: "My modules",
    },
    LessonItem: {
      until: "Until",
    },
    UsefulLinks: {
      added: "Added",
      groupName: "Group name:",
      NoLinks: "No links",
    },
    Words: {
      Filter: "Filter",
      clear: "clear",
      SelectedForStudy: "Selected for study",
      StartLesson: "Start Lesson",
      fromUnitWords: "from Unit words",
      allWords: "all words",
      addedByMe: "added by me",
      studied: "studied",
    },
    StudentPageModule: {
      TotalProgress: "Total Progress",
      Result: "Result",
    },
    Module: {
      UnitExercises: "Unit",
      TestResults: "Test Results",
      TotalProgress: "Total progress",
      TotalProgressTooltip: "Program completion in percent, excluding tests",
      ResultsTooltip: "Percetage of correctly completed exercises",
      Results: "Results",
      ClassesPlanned: "Classes, Planned",
      HRSDone: "HRS,  done",
      exercise_one: "exercise",
      exercise_few: "exercises",
      ex: "ex.",
      CurrentLevel: "Current level {{level, string}}",
    },
    EmptyTable: {
      FirstTextDefault: "There are no groups for you.",
      SecondTextDefault: "Please contact your study coordinator.",
      FirstTextDistributor: "There is no data to be displayed.",
      SecondTextDistributor: "Try to enter some data.",
      FirstTextClasses: "There is no data to be displayed.",
      SecondTextClasses: "Try changing filters or contact support.",
    },
    ModalAttendance: {
      status: "Status",
      students: "Students",
      checkAll: "Check all",
      checkStatusNotify: "Check if the status of the lesson is set correctly!",
      comment: "Comment",
      apiError:
        "Your data isn't saved. Please contact tech support online@dschool.ru",
      submit: "Submit",
      restrictiveBackDateError: "Date cannot be less than allowed",
      restrictiveForwardDateError: "Date cannot be more than allowed",
      commentSizeError: "Comment must be less than {{count}} words",
      requiredFieldError: "This field is required",
      requiredDateError: "Fill date and time",
      ExcessHoursWarning:
        "The number of hours is exceeded, contact the project coordinator",
      MarkStudents:
        "Mark the students who attended the class and save the changes.",
      UnitLabel: "Unit",

      early_cancel_by_customer_not_acceptable:
        "Early cancellation by user is not available, 2 classes have already been cancelled, contact the coordinator",
      early_cancel_by_school_not_acceptable:
        "Early cancellation by the school is not available, 2 classes have already been cancelled, contact the coordinator",
    },
    TeacherMyClasses: {
      group: "Group",
      status: "Status",
      dateTime: "Date & time",
      students: "Students",
      duration: "Duration",
      webinarCredentials: "Webinar: Log/Pas",
      StandardHelper: "Nothing was found.",
    },
    StartPageTeacher: {
      myClasses: "My classes",
      onlyFlexible: "Only Flexible",
    },
    StartUsefulLinks: {
      title: "Useful links",
    },
    HRWidget: {
      TotalActiveGroups: "Total Active Groups",
      TotalActiveStudents: "Total Active Students",
      TotalActiveLicenses: "Total Active Licenses",
    },
    LessonDuration: {
      Minutes: "min.",
      Hours: "ac.h.",
    },
    Report: {
      Title: "Report title",
      CreationDate: "Creation date",
      Period: "Period",
      Status: "Status",
      StatusDone: "Done",
      StatusPending: "Pending",
      Actions: "Actions",
      Statistics: "Platform statistics",
      CourseProgress: "Course progress",
      EntranceTest: "Entrance testing",
      LecenseReport: "Used licenses report",
      HeaderTitle: "Reports",
      CreateReport: "Create report",
      DeleteReport: "Are you sure?",
      DeleteTooltip: "Remove",
      MailTooltip: "Send to email",
      DownloadTooltip: "Download",
      DeleteGroupTooltip: "Remove selected",
      MailGroupTooltip: "Send selected to email",
      DownloadGroupTooltip: "Download selected",
    },
    CreateReport: {
      Cancel: "Cancel",
      Create: "Create",
      FromDate: "From",
      ToDate: "To",
      Date: "Date",
      FilterTitle: "Data filter for building a report",
      FilterGroupByTeacher: "Group by teacher",
      FilterListGroup: "Group",
      FilterListStudent: "Student",
      FilterListCompany: "Customer company",
      FilterListTest: "Entrance test name",
      FilterListSelectedStudents: "Selected {{count}} students",
      FilterListSelectedGroups: "Selected {{count}} groups",
      FilterListSelectedCompanies: "Selected {{count}} companies",
      FilterListSelectedTests: "Selected {{count}} entrance tests",
      FilterListSelectedChipsStudents: "{{count}} students",
      FilterListSelectedChipsGroups: "{{count}} groups",
      FilterListSelectedChipsCompanies: "{{count}} customer companies",
      FilterListSelectedChipsTests: "{{count}} entrance tests",
      FilterListSelectedPlaceholderStudents: "Select students",
      FilterListSelectedPlaceholderGroups: "Selected groups",
      FilterListSelectedPlaceholderCompanies: "Selected customer companies",
      FilterListSelectedPlaceholderTests: "Select entrance tests",
      GroupListTitle: "Selected groups ({{count}})",
      GroupListGroup: "Group",
      GroupListSelectAll: "Select all",
      StudentTitle: "Create statistics report",
      TeacherTitle: "Create course progress report",
      StudentType: "Platform statistics",
      TeacherType: "Course progress",
      ReportTitlePlaceholder: "Report title",
      EmptyGroupsError: "Add one or more groups!",
      OrderCreateError: "Failed to create new report",
    },
    MyWords: {
      Title: "My words",
      LearnWords: "Learn The Words",
      Learned: "learned",
      FilterTitle: "Filter",
      FilterClear: "clear",
      FromUnit: "From course unit",
      AddedToMe: "Added to me",
      SelectCourse: "Select course",
      SelectUnit: "Select unit",
      SelectType: "Select type",
      HideAll: "Hide all",
      ShowAll: "Show all",
      AllWords: "all words",
      WordsFromUnit: "words from course unit",
      AddedByMe: "added by me",
      MissingTranslation: "Translation is missing",
      FilterUnlearned: "Only not learnt",
      FilterAllWords: "Repeat all words",
      FilterLearned: "Only learnt",
      CourseNotSelected: "Course not selected",
      UnitNotSelected: "Unit not selected",
      EmptyWordList: "Select course and unit in the filter",
      NotEnoughWords:
        "The number of words that are not enough for the lesson, choose more words to study",
    },
    Notifications: {
      Title: "Recent Notifications",
      HaveRead: "Have Read",
      StandardHelper: "Nothing was found",
    },
    Profile: {
      Title: "Profile",
      PersonalInformation: "Personal information",
      Licenses: "Licenses",
      ChangePassword: "Change password",
      Settings: "Settings",
      FirstName: "Name",
      LastName: "Last Name",
      Country: "Country",
      TimeZone: "Time zone",
      Company: "Company",
      City: "City",
      Sector: "Sector",
      Position: "Position",
      SaveData: "Save data",
      Change: "Change",
      OldPassword: "Old password",
      NewPassword: "New password",
      RepeatPassword: "Repeat password",
      LicenseNumber: "License number",
      LicenseStatus: "Status",
      LicenseActivated: "Activated",
      LicenseExpiresAt: "Valid until",
      LicenseRemainsDays: "Remains Days",
      ShowPassingResults: "Display the result of the passage:",
    },
    TeachersAutocompleteFilter: {
      EnterTeacherName: "Teachers not found",
      SelectTeacher: "Teacher select",
    },
    SetupPasswordRedirect: {
      Title: "Action required",
      Description: "You must set a password to continue using this service.",
      Redirect: "Set password",
    },
    AuthAccessDenied: {
      Title: "Access denied",
      Subtitle: "You are not authorized to view this page",
      Please: "Please",
      SignIn: "sign in",
      Or: "or",
      GoToMain: "go to main page",
    },
    SetupPassword: {
      Title: "Password setup",
      Subtitle:
        "You need to set a new password, enter the new password and confirmation in the fields below",
    },
    ChangePassword: {
      Password: "Password",
      PasswordRepeat: "Password repeat",
      Confirm: "Confirm",
      PasswordShortError: "Password length must be greater than 6 characters",
      PasswordMismatch: "Passwords must match",
      PasswordChangeSuccess: "Password changed successfully",
    },
    PasswordChangeStrength: {
      VeryWeak: "Very weak",
      Weak: "Weak",
      Normal: "Normal",
      Good: "Good",
      Strong: "Strong",
    },
    EntranceTest: {
      ModalTitle: "Determine your level of knowledge",
      ModalBody:
        "Take the test to determine your level of English proficiency. The test takes an average of 40 minutes.",
      Cancel: "Cancel",
      Take: "Take the test",
    },
    Payroll: {
      Groups: "Groups",
      TeachersReferenceBooks: "Reference books for teachers",

      ModalGroupTitle: "Editing a group",
      ModalGroupCancel: "Cancel",
      ModalGroupSubmit: "Approve",
      ModalGroupTeacher: "Teacher",
      ModalGroupName: "Group name",
      ModalGroupStartDate: "Project maintenance date",
      ModalGroupRate: "Teacher's rate",

      ModalSurchargesTitle: "Surcharges",
      ModalSurchargesAdd: "Add Surcharge",
      ModalSurchargeType: "Type of surcharge",
      ModalSurchargeAmount: "Amount of surcharges",
      ModalSurchargeActiveFrom: "Payment start date",
      ModalNoSurcharges: "No surcharges",

      per_ac_hour: "Per hour",
      per_lesson: "Per Lesson",

      GroupsTableName: "Group",
      GroupsTableClassType: "Class type",
      GroupsTableTeacher: "Teacher",
      GroupsTableLang: "Language",
      GroupsTableRate: "Rate",
      GroupsTableApprove: "Approve",
    },
    TaggingList: {
      Title: "Teacher's reference books for tagging",
      ChangeTagModalTitle: "Tag change",
      CreateTagModalTitle: "Create a tag",
      ModalSubmit: "Submit",
      NewTag: "New tag",
      TableTagName: "Name",
      TableTagChange: "Change",
      TableTagDelete: "Delete",
    },
    Teachers: {
      Title: "Teachers",
      TableFullName: "Teacher full name",
    },
    TeacherDetails: {
      Title: "Teacher card",
      Profile: "Profile",
      EditProfile: "Edit profile",

      ProfileModalTitle: "Profile editing",
      ProfileModalSubmit: "Save profile",
      ProfileModalFormLastName: "Last Name",
      ProfileModalFormFirstName: "First name",
      ProfileModalFormMiddleName: "Middle name",
      ProfileModalFormPhone: "Phone",

      RatesTitle: "Languages, activities and rates",
      RatesAddLang: "Add Language",
      RatesAddRate: "Add class type",
      RatesShowHistory: "View appointment history",
      RatesHideHistory: "Hide appointment history",
      RatesTableClassType: "Class Type",
      RatesTableAmount: "Rate in rubles",
      RatesTableActiveFrom: "Appointment date",
      RatesTableEdit: "Edit",

      LangModalTitle: "Adding a language",
      LangModalSubmit: "Submit",
      LangModalCancel: "Cancel",
      LangModalFormLang: "Select language",
      LangModalFormNativeSpeaker: "Native speaker",
      LangModalFormClassType: "Class type",
      LangModalFormAmount: "Amount",
      LangModalFormActiveFrom: "Application date",
      LangModalAddClassType: "Add class type",

      CreateRateModalTitle: "Adding a class type",
      ChangeRateModalTitle: "Editing a class type",
      RateModalSubmit: "Submit",
      RateModalCancel: "Cancel",
      RateModalFormClassType: "Class type",
      RateModalFormAmount: "Amount",
      RateModalFormActiveFrom: "Application date",

      RequiredFieldError: "This field is required",
      ApiError:
        "Your data isn't saved. Please contact tech support online@dschool.ru",
    },
    EmailNotConfirmed: {
      Title:
        "Для входа на платформу на ваш электронный адрес, {email}, отправлено письмо. Просим подтвердить ваш адрес. В случае возникновения вопросов пишите online@dschool.ru",
    },
    Languages: {
      English: "English",
      Russian: "Russian",
      German: "German",
      Uzbek: "Uzbek",
      Tajik: "Tajik",
      Tatar: "Tatar",
    },
    Summary: {
      Title: "Summary",
      Empty: "No data to generate report",
      LessonStatus: "Lesson statuses",
      LessonCount: "Lesson count",
      HoursCount: "Number of academic hours",
      Form: "Form",
      planned: "planned",
      completed: "completed",
      compensation: "compensation",
      earlyCanceledByCustomer: "timely cancellation by client",
      earlyCanceledBySchool: "timely cancellation by DS",
      lateCanceledByCustomer: "untimely cancellation by client",
      lateCanceledBySchool: "untimely cancellation by DS",
      notPerformedToPay: "not performed to pay",
    },
    CompaniesReport: {
      Title: "Companies",
      FilterStartDate: "From date *",
      FilterEndDate: "To date *",
      FilterDateRequired: "Fill in the date",
      FilterSelectCompany: "Select company",
      FilterAllCompanies: "All companies",
      Process: "Form",

      SummaryCompanies: "Companies",
      SummaryGroups: "Groups",
      SummaryPayment: "Sales amount",
      SummaryHours: "Number of hours",
      SummaryAverageHourPrice: "Average price ac. hours",

      TableCompanyName: "Company",
      TableGroup: "Group",
      TableLessons: "Lessons",
      TableAcademicHours: "Academic Hours",
      TablePayment: "Payment",
      TablePrice: "Avg price",
    },
    CancellationReport: {
      Title: "Cancellation report",

      FilterStartDate: "From date *",
      FilterEndDate: "To date *",
      FilterDateRequired: "Fill in the date",
      FilterSelectCompany: "Select company",
      FilterAllCompanies: "All companies",
      Process: "Form",

      TabCompanies: "company report",
      TabTeachers: "report on teachers",

      TableTeacher: "Teacher",
      TableCompany: "Company",
      TableCompleted: "Completed",
      TableLateCanceledByCustomer: "Late canceled by customer",
      TableEarlyCanceledByCustomer: "Early canceled by customer",
      TableLateCanceledBySchool: "Late canceled by DS",
      TableEarlyCanceledBySchool: "Early canceled by DS",

      StandardHelper:
        "Чтобы отобразить данные, воспользуйтесь хотя бы одним фильтром.",
      Download: "Download",
      DownloadName: "CancellationReport",
    },
    Promocode: {
      Expired:
        "Enrollment in the group has been discontinued. Contact your manager",
    },
    GroupsAutocompleteFilter: {
      EnterGroupName: "Enter group name",
      SelectGroup: "Group select",
    },
    Loading: {
      Error: "Loading error, try refreshing the page",
    },
  },
  ru: {
    Global: {
      Today: "Сегодня",
      h: "ч",
      Loading: "Загрузка",
      Details: "Детали",
      Information: "Текущая статистика",
      Modules: "Модули",
      Groups: "Группы",
    },
    TeachersAutocompleteFilter: {
      EnterTeacherName: "Учителя не найдены",
      SelectTeacher: "Выбор учителя",
    },
    DatePicker: {
      Today: "Сегодня",
      Clear: "Очистить",
    },
    DateRangePicker: {
      StartDate: "Дата начала",
      EndDate: "Дата завершения",
    },
    Button: { Send: "Отправить", Download: "Скачать" },
    Distributor: {
      Report: "Отчеты",
      DateFrom: "С даты",
      DateTo: "По дату",
      Status: "Статус",
      StatusReady: "Готов",
      StatusPlanned: "Обрабатывается",
      Actions: "Действия",
    },
    AllModules: "Все модули",
    MyModules: {
      title: "Мои модули",
      levelPlaceholder: "Выбрать уровень",
      typePlaceholder: "Выберите тип",
    },
    Login: {
      title: "Вход",
      subTitle: "Заполните поля ниже, чтобы войти в свою учетную запись.",
      email: "Email адрес",
      password: "Пароль",
      restorePasswordTitle: "Восстановление пароля",
      restorePasswordSubTitle:
        "Введите email указанный Вами при регистрации и нажмите кнопку «Восстановить»",
      restorePassword: "Забыли Ваш пароль?",
      toSignIn: "Назад на страницу авторизации",
      restore: "Восстановить",
      restorePasswordSuccess:
        "В течение нескольких минут Вы получите письмо с инструкциями по восстановлению Вашего пароля.",
      toRegister: "Зарегистрироваться",
    },
    Register: {
      title: "Регистрация",
      subTitle: "Заполните поля ниже, чтобы зарегистрироваться.",
      email: "Email адрес",
      firstName: "Имя",
      lastName: "Фамилия",
      phone: "Телефон",
      password: "Пароль",
      passwordConfirmation: "Подтверждение пароля",
      toSignIn: "Назад на страницу авторизации",
      requiredFieldError: "Это поле обязательно к заполнению",
      invalidEmailError: "Некорректный формат почты",
      invalidPhoneError: "Некорректный формат номера телефона",
      tooShortError: "Слишком коротко",
      toLogin: "Вход",
    },
    LessonStatuses: {
      planned: "Запланировано",
      completed: "Проведено",
      studentLateCancellation: "Поздняя отмена слушателями",
      earlyCanceledByCustomer: "Заблаговременная отмена слушателями",
      earlyCanceledBySchool: "ДС Заблаговременная отмена",
      lateCanceledByCustomer: "Поздняя отмена слушателями",
      lateCanceledBySchool: "ДС Поздняя отмена",
      notPerformedToPay: "К оплате",
    },
    LessonCredentials: {
      copied: "Скопировано в буфер обмена",
    },
    Menu: {
      Dashboard: "Главная",
      Groups: "Группы",
      Reports: "Отчеты",
      Classes: "Занятия",
      Modules: "Модули",
      "My words": "Лексика",
      "My courses": "Мои курсы",
      "My modules": "Мои модули",
      "Teacher’s corner": "Уголок учителя",
      "Student's corner": "Уголок ученика",
      "Additional modules": "Доп. модули",
      "Useful links": "Полезные ссылки",
      Chat: "Чат",
      Student: "Студент",
      Teacher: "Учитель",
      Coordinator: "Координатор",
      PayrollAccountant: "Зарплата",
      Attendance: "Посещаемость",
      Teachers: "Преподователи",
      Salaries: "Зарплаты",
      Odds: "Коэффициенты",
      Summary: "Сводка",
      Companies: "Компании",
      Cancellation: "Отмены",
      AllPayrollGroups: "Доплаты",
    },
    Footer: {
      "Sign up club": "Записаться на разговорный клуб Fun Friday",
      "Sign up classes": "Записаться на практические занятия",
      "Practice on AI": "Потренироваться  на языковых AI тренажерах",
      About: "О компании Прогресс",
      UserAgreement: "Пользовательское соглашение",
      VKontakte: "вКонтакте",
      Facebook: "Facebook",
      Instagram: "Instagram",
      "Terms & Conditions":
        "Положение об обработке и защите персональных данных",
      "Privacy Policy": "Политика использования данных",
      Copyright: "",
    },
    Progress: {
      totalProgress: "Общий прогресс",
      completed: "Готово",
    },
    StartPageStudent: {
      myClasses: "Mои уроки",
      myModules: "Мои модули",
    },
    LessonItem: {
      until: "До",
    },
    BottomPanel: {
      Select: "Выбрать",
      Reload: "Обновить",
      Next: "Далее",
    },
    CardClass: {
      WatchRecording: "Посмотреть запись",
      Cancelation: "Поздняя отмена слушателями",
      Join: "Войти на вебинар",
    },
    CardModule: {
      ends: "Завершается {{date, string}}",
      continue: "Продолжить",
      progressTitleTotal: "Выполнено",
      progressTitleResult: "Правильно",
      progressTitleUnits: "Часы",
      progressTitleClasses: "Занятия",
    },
    UsefulLinks: {
      added: "Добавлен",
      groupName: "Учебная группа:",
      NoLinks: "Ссылок нет",
    },
    Words: {
      Filter: "Фильтр",
      clear: "очистить",
      SelectedForStudy: "Выбранные для изучения",
      StartLesson: "Начать урок",
      fromUnitWords: "слова из темы",
      allWords: "все слова",
      addedByMe: "добавлено мной",
      studied: "studied",
    },
    StudentPageModule: {
      TotalProgress: "Общий прогресс",
      Result: "Результат",
    },
    Module: {
      UnitExercises: "Раздел",
      TestResults: "Результаты теста",
      TotalProgress: "Общий прогресс",
      TotalProgressTooltip:
        "Процент выполнения программы курса без учета тестов",
      ResultsTooltip: "Процент правильно выполненных упражнений",
      Results: "Результаты",
      ClassesPlanned: "Занятия, в плане",
      HRSDone: "HRS,  выполненных",
      exercise_one: "упражнение",
      exercise_two: "упражнения",
      exercise_few: "упражнений",
      ex: "упр.",
      CurrentLevel: "Текущий уровень {{level, string}}",
    },
    EmptyTable: {
      FirstTextDefault: "Вам не назначены учебные модули.",
      SecondTextDefault: "Обратитесь к вашему координатору обучения.",
      FirstTextDistributor: "Нет данных для отображения.",
      SecondTextDistributor: "Попробуйте внести какие нибудь данные.",
      FirstTextClasses: "Нет данных для отображения.",
      SecondTextClasses:
        "Попробуйте поменять фильтры или обратитесь к поддержке.",
    },
    ModalAttendance: {
      status: "Статус",
      students: "Студенты",
      checkAll: "Отметить всех",
      checkStatusNotify: "Проверьте чтобы статус урока был выбран корректно!",
      comment: "Комментарий",
      apiError:
        "Ваши изменения не сохранены. Пожалуйста напишите на online@dschool.ru",
      submit: "Готово",
      restrictiveBackDateError: "Дата не может быть меньше разрешенной",
      restrictiveForwardDateError: "Дата не может быть больше разрешенной",
      commentSizeError: "Комментарий должен быть меньше {{count}} слов",
      requiredFieldError: "Это поле обязательно к заполнению",
      requiredDateError: "Заполните дату и время",
      ExcessHoursWarning:
        "Количество часов превышено, свяжитесь с координатором проекта или сократите продолжительность занятия",
      MarkStudents:
        "Отметьте учащихся, которые посетили занятие, и сохраните изменения.",
      UnitLabel: "Раздел",

      early_cancel_by_customer_not_acceptable:
        "Ранняя отмена пользователем недоступна, уже отменены 2 занятия, обратитесь к координатору",
      early_cancel_by_school_not_acceptable:
        "Ранняя отмена школой недоступна, уже отменены 2 занятия, обратитесь к координатору",
    },
    TeacherMyClasses: {
      group: "Группа",
      status: "Статус",
      dateTime: "Дата и время",
      students: "Студенты",
      duration: "Продолжительность",
      webinarCredentials: "Вебинар: Логин/Пароль",
      StandardHelper: "Ничего не нашлось.",
    },
    StartPageTeacher: {
      myClasses: "Мои занятия",
      onlyFlexible: "Только гибкие",
    },
    StartUsefulLinks: {
      title: "Полезные ссылки",
    },
    HRWidget: {
      TotalActiveGroups: "Общее количество активных групп",
      TotalActiveStudents: "Общее количество активных студентов",
      TotalActiveLicenses: "Общее количество активных лицензий",
    },
    LessonDuration: {
      Minutes: "мин.",
      Hours: "ак.ч.",
    },
    Report: {
      Title: "Название отчета",
      CreationDate: "Дата создания",
      Period: "Период",
      Status: "Статус",
      StatusDone: "Готово",
      StatusPending: "Обрабатывается",
      Actions: "Действия",
      Statistics: "Статистика по платформе",
      CourseProgress: "Прогресс по курсу",
      EntranceTest: "Входное тестирование",
      LecenseReport: "Отчет по использованным лицензиям",
      HeaderTitle: "Отчеты",
      CreateReport: "Создать отчет",
      DeleteReport: "Вы уверены?",
      DeleteTooltip: "Удалить",
      MailTooltip: "Отправить на email",
      DownloadTooltip: "Скачать",
      DeleteGroupTooltip: "Удалить выбранные",
      MailGroupTooltip: "Отправить выбранные на email",
      DownloadGroupTooltip: "Скачать выбранные",
    },
    CreateReport: {
      Cancel: "Отмена",
      Create: "Создать",
      FromDate: "От",
      ToDate: "По",
      Date: "Дата",
      FilterTitle: "Фильр данных для построения отчета",
      FilterGroupByTeacher: "Группировать по учителю",
      FilterListGroup: "Группа",
      FilterListStudent: "Студент",
      FilterListCompany: "Компания заказчик",
      FilterListTest: "Название входного отчета",
      FilterListSelectedStudents: "Выбрано {{count}} студентов",
      FilterListSelectedGroups: "Выбрано {{count}} групп",
      FilterListSelectedCompanies: "Выбрано {{count}} компаний",
      FilterListSelectedTests: "Выбрано {{count}} входных тестов",
      FilterListSelectedChipsStudents: "{{count}} студентов",
      FilterListSelectedChipsGroups: "{{count}} групп",
      FilterListSelectedChipsCompanies: "{{count}} компаний заказчиков",
      FilterListSelectedChipsTests: "{{count}} входных тестов",
      FilterListSelectedPlaceholderStudents: "Выбрать студентов",
      FilterListSelectedPlaceholderGroups: "Выбрать группы",
      FilterListSelectedPlaceholderCompanies: "Выбрать компании заказчиков",
      FilterListSelectedPlaceholderTests: "Выбрать входные тесты",
      GroupListTitle: "Выбрано групп ({{count}})",
      GroupListGroup: "Группа",
      GroupListSelectAll: "Выбрать все",
      StudentTitle: "Создание отчета по статистике",
      TeacherTitle: "Создания отчета по прогрессу курса",
      StudentType: "Статистика по платформе",
      TeacherType: "Прогресс по курсу",
      ReportTitlePlaceholder: "Название отчета",
      EmptyGroupsError: "Добавьте одну или больше групп!",
      OrderCreateError: "Не удалось создать новый отчет",
    },
    MyWords: {
      Title: "Лексика",
      LearnWords: "Учить слова",
      FilterTitle: "Фильтры",
      FilterClear: "очистить",
      Learned: "изучено",
      FromUnit: "Из раздела курса",
      AddedToMe: "Добавлены мной",
      SelectCourse: "Выбрать курс",
      SelectUnit: "Выбрать раздел",
      SelectType: "Выбрать действие",
      HideAll: "скрыть все",
      ShowAll: "показать все",
      AllWords: "все слова",
      WordsFromUnit: "из разделов курсов",
      AddedByMe: "добавлены мной",
      MissingTranslation: "Перевод отсутствует",
      FilterUnlearned: "Только не выученные",
      FilterAllWords: "Повторить все слова",
      FilterLearned: "Только выученные",
      CourseNotSelected: "Курс не выбран",
      UnitNotSelected: "Раздел не выбран",
      EmptyWordList: "Выберите курс и раздел в фильтре",
      NotEnoughWords:
        "Количество слов не достаточно для урока, выберите еще слова для изучения",
    },
    Notifications: {
      Title: "Уведомления",
      HaveRead: "Прочитанные",
      StandardHelper: "Ничего не нашлось",
    },
    Profile: {
      Title: "Профиль",
      PersonalInformation: "Персональные данные",
      Licenses: "Лицензии",
      ChangePassword: "Смена пароля",
      Settings: "Настройки",
      FirstName: "Имя",
      LastName: "Фамилия",
      Country: "Страна",
      TimeZone: "Часовой пояс",
      Company: "Компания",
      City: "Город",
      Sector: "Сектор",
      Position: "Должность",
      SaveData: "Сохранить",
      Change: "Изменить",
      OldPassword: "Старый пароль",
      NewPassword: "Новый пароль",
      RepeatPassword: "Новый пароль ещё раз",
      LicenseNumber: "№ лицензии",
      LicenseStatus: "Статус",
      LicenseActivated: "Активирована",
      LicenseExpiresAt: "Действительна до",
      LicenseRemainsDays: "Дней до окончания",
      ShowPassingResults: "Отображать результат прохождения:",
    },
    SetupPasswordRedirect: {
      Title: "Требуется действие",
      Description:
        "Вы должны установить пароль, чтобы продолжить пользоваться данным сервисом",
      Redirect: "Установить пароль",
    },
    AuthAccessDenied: {
      Title: "Ошибка доступа",
      Subtitle: "У вас нет прав для просмотра данной страницы",
      Please: "Пожалуйста",
      SignIn: "войдите",
      Or: "или",
      GoToMain: "на главную",
    },
    SetupPassword: {
      Title: "Установка пароля",
      Subtitle:
        "Вам необходимо установить новый пароль, введите новый пароль и подтверждение в поля ниже",
    },
    ChangePassword: {
      Password: "Пароль",
      PasswordRepeat: "Подтверждение пароля",
      Confirm: "Подтвердить",
      PasswordShortError: "Длина пароля должна быть больше 6 символов",
      PasswordMismatch: "Пароли должны совпадать",
      PasswordChangeSuccess: "Пароль успешно изменен",
    },
    PasswordChangeStrength: {
      VeryWeak: "Очень слабый",
      Weak: "Слабый",
      Normal: "Нормальный",
      Good: "Хороший",
      Strong: "Сильный",
    },
    EntranceTest: {
      ModalTitle: "Определите уровень знаний",
      ModalBody:
        "Пройдите тест, чтобы определить ваш уровень владения английским языком.Прохождение тестирования занимает в среднем 40 минут.",
      Cancel: "Отмена",
      Take: "Перейти к заданию",
    },
    Payroll: {
      Groups: "Группы",
      TeachersReferenceBooks: "Справочники для преподавателей",

      ModalGroupTitle: "Редактирование группы",
      ModalGroupCancel: "Отмена",
      ModalGroupSubmit: "Утвердить",
      ModalGroupTeacher: "Преподаватель",
      ModalGroupName: "Название группы",
      ModalGroupStartDate: "Дата ведения по проект",
      ModalGroupRate: "Ставка преподавателя",

      ModalSurchargesTitle: "Доплаты",
      ModalSurchargesAdd: "Добавить доплату",
      ModalSurchargeType: "Вид доплаты",
      ModalSurchargeAmount: "Сумма доплат",
      ModalSurchargeActiveFrom: "Дата старта доплаты",
      ModalNoSurcharges: "Доплат нет",

      per_ac_hour: "За час",
      per_lesson: "За урок",

      GroupsTableName: "Группа",
      GroupsTableClassType: "Вид",
      GroupsTableTeacher: "Преподаватель",
      GroupsTableLang: "Язык",
      GroupsTableRate: "Ставка",
      GroupsTableApprove: "Утвердить",
    },
    TaggingList: {
      Title: "Справочники преподавателя для тегирования",
      ChangeTagModalTitle: "Изменение тега",
      CreateTagModalTitle: "Создание тега",
      ModalSubmit: "Отправить",
      NewTag: "Новый тэг",
      TableTagName: "Название",
      TableTagChange: "Изменить",
      TableTagDelete: "Удалить",
    },
    Teachers: {
      Title: "Преподаватели",
      TableFullName: "ФИО Преподователя",
    },
    TeacherDetails: {
      Title: "Карточка преподавателя",
      Profile: "Профиль",
      EditProfile: "Редактировать профиль",

      ProfileModalTitle: "Редактирование профиля",
      ProfileModalSubmit: "Сохранить профиль",
      ProfileModalFormLastName: "Фамилия",
      ProfileModalFormFirstName: "Имя",
      ProfileModalFormMiddleName: "Отчество",
      ProfileModalFormPhone: "Телефон",

      RatesTitle: "Языки, виды занятий и ставки",
      RatesAddLang: "Добавить Язык",
      RatesAddRate: "Добавить тип занятий",
      RatesShowHistory: "Посмотреть историю назначений",
      RatesHideHistory: "Скрыть историю назначений",
      RatesTableClassType: "Тип занятия",
      RatesTableAmount: "Ставка в рублях",
      RatesTableActiveFrom: "Дата назначения",
      RatesTableEdit: "Редактировать",

      LangModalTitle: "Добавление языка",
      LangModalSubmit: "Отправить",
      LangModalCancel: "Отмена",
      LangModalFormLang: "Выбрать язык",
      LangModalFormNativeSpeaker: "Носитель языка",
      LangModalFormClassType: "Тип занятия",
      LangModalFormAmount: "Ставка",
      LangModalFormActiveFrom: "Дата применения",
      LangModalAddClassType: "Добавить тип занятий",

      CreateRateModalTitle: "Добавление типа занятий",
      ChangeRateModalTitle: "Редактирование типа занятий",
      RateModalSubmit: "Отправить",
      RateModalCancel: "Отмена",
      RateModalFormClassType: "Тип занятия",
      RateModalFormAmount: "Ставка",
      RateModalFormActiveFrom: "Дата применения",

      RequiredFieldError: "Это обязательное поле",
      ApiError:
        "Ваши изменения не сохранены. Пожалуйста напишите на online@dschool.ru",
    },
    EmailNotConfirmed: {
      Title: "",
    },
    Languages: {
      English: "Английский",
      Russian: "Русский",
      German: "Немецкий",
      Uzbek: "Узбекский",
      Tajik: "Таджикский",
      Tatar: "Татарский",
    },
    Summary: {
      Title: "Сводка",
      LessonStatus: "Статусы занятий",
      LessonCount: "Количество занятий",
      HoursCount: "Количество ак. часов",
      Empty: "Нет данных для формирования отчета",
      Form: "Сформировать",
      planned: "Запланировано",
      completed: "проведено",
      compensation: "компенсации",
      earlyCanceledByCustomer: "отмена клиентом своевременная",
      earlyCanceledBySchool: "отмена ДС своевременная",
      lateCanceledByCustomer: "отмена клиентом несвоевременная",
      lateCanceledBySchool: "отмена ДС несвоевременная",
      notPerformedToPay: "к оплате",
    },
    CompaniesReport: {
      Title: "Компании",
      FilterStartDate: "С даты *",
      FilterEndDate: "По дату *",
      FilterDateRequired: "Заполните дату",
      FilterSelectCompany: "Выбрать компанию",
      FilterAllCompanies: "Все компании",
      Process: "Сформировать",

      SummaryCompanies: "Компании",
      SummaryGroups: "Группы",
      SummaryPayment: "Сумма реализации",
      SummaryHours: "Количество часов",
      SummaryAverageHourPrice: "Средняя цена ак. часа",

      TableCompanyName: "Компания",
      TableGroup: "Группа",
      TableLessons: "Занятия",
      TableAcademicHours: "Ак.ч.",
      TablePayment: "Оплата",
      TablePrice: "Ср. цена",
    },
    CancellationReport: {
      Title: "Отчет по отменам",

      FilterStartDate: "С даты *",
      FilterEndDate: "По дату *",
      FilterDateRequired: "Заполните дату",
      FilterSelectCompany: "Выбрать компанию",
      FilterAllCompanies: "Все компании",
      Process: "Сформировать",

      TabCompanies: "отчёт по компаниям",
      TabTeachers: "отчёт по преподавателям",

      TableTeacher: "Преподаватель",
      TableCompany: "Компания",
      TableCompleted: "Проведено",
      TableLateCanceledByCustomer: "Несв. отмена клиентом",
      TableEarlyCanceledByCustomer: "Своев. отмена клиентом",
      TableLateCanceledBySchool: "Несв. отмена DS",
      TableEarlyCanceledBySchool: "Своевр. отмена DS",

      StandardHelper:
        "Чтобы отобразить данные, воспользуйтесь хотя бы одним фильтром.",
      Download: "Выгрузить",
      DownloadName: "Отчет по отменам",
    },
    Promocode: {
      Expired: "Зачисление в группу прекращено. Обратитесь к  вашему менеджеру",
    },
    GroupsAutocompleteFilter: {
      EnterGroupName: "Введите название группы",
      SelectGroup: "Выбор группы",
    },
    Loading: {
      Error: "Ошибка загрузки, попробуйте обновить страницу",
    },
  },
};

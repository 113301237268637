/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-interface */
import {
  types,
  SnapshotOut,
  Instance,
  getRoot,
  getSnapshot,
} from "mobx-state-tree";

import { PassingModel } from "./PassingModel";
import { TypeModel } from "./Type";
import { RootStore } from "..";
import { Block, BlockModel } from "../Block/Block";
import { ContentImageModel } from "../ContentImage/ContentImage";
import { CourseModel } from "../Course/CourseModel";
import { withTranslation } from "../extensions/withTranslation";
import {
  Lesson,
  completedStatuses,
  activeLessonStatuses,
} from "../Lesson/LessonModel";
import { TopicModel } from "../Topic/Topic";
import { TranslationModel } from "../Translation/TranslationModel";

type LessonCompletedProps = {
  completed?: boolean;
};

export const UnitModel = types
  .model("UnitModel", {
    id: types.identifier,
    uuid: types.optional(
      types.union(types.string, types.literal(undefined), types.literal(null)),
      undefined
    ),
    childrenListUpdatedAt: types.maybeNull(types.Date),
    // exercisesCount: types.maybeNull(types.number),
    code: types.maybeNull(types.string),
    maxMark: types.maybeNull(types.number),
    isForTesting: types.maybeNull(types.boolean),
    course: types.maybeNull(types.late(() => types.reference(CourseModel))),
    order: types.maybeNull(types.number),
    timeLimit: types.maybeNull(types.number),
    topic: types.maybeNull(TopicModel),
    translationsAttributes: types.optional(types.array(TranslationModel), []),
    type: types.maybeNull(TypeModel),
    updatedAt: types.maybeNull(types.Date),
    picture: types.maybeNull(types.string),
    lmsSections: types.optional(types.array(types.string), []),
    available: types.maybeNull(types.boolean),
    mark: types.maybeNull(types.number),
    // Results
    markPercent: types.maybeNull(types.number),
    markTestPercent: types.maybeNull(types.number),
    passedAt: types.maybeNull(types.Date),
    // Total progress
    passingPercent: types.maybeNull(types.number),
    passingTestPercent: types.maybeNull(types.number),
    startedTimestamp: types.maybeNull(types.Date),
    state: types.maybeNull(types.string),
    // timer: types.maybeNull(types.number),
    blocks: types.optional(
      types.array(types.late(() => types.reference(BlockModel))),
      []
    ),
    contentImages: types.optional(types.array(ContentImageModel), []),
    passing: types.maybeNull(PassingModel),
  })
  .extend(withTranslation)
  .views((self) => ({
    get sortedBlocks(): Block[] {
      return self.blocks
        .slice()
        .sort((a, b) => Number(a.order) - Number(b.order));
    },
    get allLessons(): Lesson[] {
      return getRoot<RootStore>(self)
        .lesson.items.filter(
          (lesson: Lesson) => getSnapshot(lesson).unit === self.id
        )
        .sort(
          (a, b) =>
            (a?.startAtLocal?.getTime() || Infinity) -
            (b?.startAtLocal?.getTime() || Infinity)
        );
    },
    get firstBlock(): Block | undefined {
      return self.blocks.length > 0 ? self.blocks[0] : undefined;
    },
    lessons({ completed }: LessonCompletedProps): Lesson[] {
      let lessons = getRoot<RootStore>(self)
        .lesson.items.filter(
          (lesson: Lesson) =>
            getSnapshot(lesson).unit === self.id &&
            activeLessonStatuses.includes(lesson.currentStatus)
        )
        .filter((lesson: Lesson) => !lesson?.compensation);
      if (completed === true) {
        lessons = lessons.filter((lesson) =>
          completedStatuses.includes(lesson?.currentStatus)
        );
      }
      if (completed === false) {
        lessons = lessons.filter((lesson) =>
          ["planned"].includes(lesson?.currentStatus)
        );
      }
      lessons = lessons.sort(
        (a, b) =>
          (a?.startAtLocal?.getTime() || Infinity) -
          (b?.startAtLocal?.getTime() || Infinity)
      );
      return lessons;
    },
  }))
  .views((self) => ({
    get firstBlock(): Block | undefined {
      return self.blocks.slice()[0];
    },
    get availableBlocksForTesting(): Block[] {
      return self.blocks.filter((block, i, arr) => {
        if (i === 0) {
          return true;
        }

        if (block.state !== "notStarted") {
          return true;
        }

        return arr[i - 1].state === "finished";
      });
    },
    getBlock(id: string | undefined): Block | undefined {
      return self.blocks.find((block) => block.id === id);
    },
  }))
  .views((self) => ({
    get nameWithoutUnit(): string {
      return self.nameTranslated.replace(/(Unit|Юнит)\s[0-9]+\./i, "");
    },
    get exercisesCount(): number | null {
      const result = self.blocks.reduce(
        (acc, block) => acc + Number(block.exerciseCount),
        0
      );
      return result || null;
    },
    get section(): string | undefined {
      return self.lmsSections?.[0];
    },
    get totalProgress(): number {
      return (
        (self.course?.isForTesting
          ? self.passingTestPercent
          : self.passingPercent) ?? 0
      );
    },
    get resultProgress(): number {
      return (
        (self.course?.isForTesting ? self.markTestPercent : self.markPercent) ??
        0
      );
    },
    get isCompleted() {
      if (self.lessons({}).length === 0) return false;
      return self.lessons({ completed: false }).length === 0;
    },
    get isCompletedNumber() {
      const lessonCompleted = self.lessons({ completed: true }).length;
      const lessonTotal = self.lessons({}).length;
      if (self.lessons({}).length === 0) return 0;
      return lessonCompleted / lessonTotal;
    },
    get testResults() {
      const testBlock = self.blocks.find(
        (block: Block) => block?.type?.code === "Test"
      );

      const completedExercises =
        testBlock?.exercises.filter((exercise) => exercise?.isPassed).length ||
        0;
      const exercisesCount = testBlock?.exercises.length || 0;
      if (!completedExercises || !exercisesCount) return 0;
      return Math.round((completedExercises / exercisesCount) * 100);
    },
    isBlockAvailableForTesting(blockId: string): boolean {
      return self.availableBlocksForTesting.some(({ id }) => id === blockId);
    },

    get totalBlocks(): number {
      return self.blocks.length;
    },
    get totalBlocksPassed(): number {
      return self.blocks.filter((blocks) => blocks.isPassed).length;
    },
    get totalBlocksWrong(): number {
      return self.blocks.filter((blocks) => blocks.isWrong).length;
    },
  }))
  .views((self) => ({
    get progress(): number {
      const lessonsCompleted = self.lessons({ completed: true });
      return Math.round(lessonsCompleted.length / self.lessons({}).length);
    },
    get classesPlanned(): number {
      return self?.lessons({}).length || 0;
    },

    get totalBlocksSkipped(): number {
      return (
        self.totalBlocks - (self.totalBlocksPassed + self.totalBlocksWrong)
      );
    },
    get isPassed(): boolean {
      if (self.totalBlocks === 0) return false;

      return (
        self.totalBlocksPassed === self.totalBlocks ||
        (self.totalBlocksPassed > 0 && self.totalBlocksWrong === 0)
      );
    },
    get firstNotPassedBlock(): Block | undefined {
      return self.sortedBlocks.find((block) => !block.isPassed);
    },
  }))
  .views((self) => ({
    get lessonsHours(): number {
      return self.lessons({}).reduce((acc, lesson) => {
        return acc + lesson.duration;
      }, 0);
    },
    get completedLessonsHours(): number {
      return self.lessons({ completed: true }).reduce((acc, lesson) => {
        return acc + lesson.duration;
      }, 0);
    },
    get contentImageLongForExercise() {
      return self.contentImages.find((image) => image.isLongForExercise);
    },
    get contentImageForPreview() {
      return self.contentImages.find((image) => image.isForPreview);
    },
  }))
  .actions((self) => ({
    // eslint-disable-next-line consistent-return
    getBlocks: async () => {
      const rootStore = getRoot<RootStore>(self);
      if (!self.course?.id) {
        return undefined;
      }
      const unitResult = await rootStore.getUnit(
        {
          courseId: self.course?.id,
          unitId: self.id,
        },
        true
      );
      const sectionId = unitResult?.unit?.lmsSections?.[0];
      if (!sectionId) return undefined;
      await rootStore.getBlocks(
        {
          courseId: self.course?.id,
          unitId: self.id,
          sectionId,
        },
        true
      );
    },
  }));

type UnitModelType = Instance<typeof UnitModel>;
export interface Unit extends UnitModelType {}
type UnitModelTypeSnapshotType = SnapshotOut<typeof UnitModel>;
export type UnitSnapshot = UnitModelTypeSnapshotType;
